<template>
  <v-container fluid>
    <page-header title="設定用戶組別">
      <template #rightSlot>
        <v-btn depressed color="primary accent-4" class="white--text" :to="{ name: 'CreateStudentGroup' }">新增</v-btn>
      </template>
    </page-header>

    <datatable
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page.sync="tablePage"
      :pageLimit.sync="tablePageLimit"
      :itemTotal="tableItemTotal"
      :isLoading="tableLoading"
      actionViewRouteLink="StudentGroupDetail"
      @options-update="getList()"
      @delete-clicked="handleDelete($event)"
    >
      <template #item.admin_type="{ item }">{{ item.admin_type | formatAdminType }} </template>
    </datatable>

    <confirm-dialog ref="confirmDeleteDialog" :loading="$store.getters.isLoading" disableCloseConfirmed />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import Datatable from '@/components/Datatable.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  name: 'Setting',
  components: {
    Datatable,
    ConfirmDialog
  },
  data: () => ({
    tableLoading: false,
    tablePage: 1,
    tablePageLimit: 10,
    tableData: [],
    tableItemTotal: 0,

    // --- static data
    tableHeaders: [
      { text: '用戶組別', value: 'name' },
      { text: '', value: 'actionViewDelete', align: 'end' },
    ],
  }),
  methods: {
    ...mapActions(['setAlert']),
    getQueries() {
      const query = this.$route.query
      if (this.$validate.regexNumber(query['p']) && parseInt(query['p']) > 0) {
        this.tablePage = parseInt(query['p'])
      }

      if (this.$validate.regexNumber(query['l']) && parseInt(query['l']) > 0) {
        this.tablePageLimit = parseInt(query['l'])
      }
      this.getList(true)
    },

    async getList(pageInit = false) {
      if (!pageInit) {
        const newQuery = {
          p: this.$validate.regexNumber(this.tablePage) && parseInt(this.tablePage) > 1 ? this.tablePage : undefined,
          l: this.$validate.regexNumber(this.tablePageLimit) && parseInt(this.tablePageLimit) !== 10 ? this.tablePageLimit : undefined,
        }

        const validNewQuery = this.$common.validNewQuery(this.$route.query, newQuery)
        if (validNewQuery) {
          this.$router.replace({ query: newQuery })
        }
      }

      this.tableLoading = true

      try {
        let payload = {
          limit: this.tablePageLimit,
          page: this.tablePage - 1,
        }

        const user = this.getCurrentUserData()
        const { data, total } = await this.$Fetcher.GetStudentGroups(payload, user.id, user.token)
        this.tableItemTotal = total
        this.tableData = data.map(el => {
          return {
            id: el.id,
            name: el.name
          }
        })
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.$store.dispatch('toggleSnack', {
            type: 'error',
            message: err,
          })
        }
        this.tableData = []
      } finally {
        this.tableLoading = false
      }
    },


    async handleDelete(id) {
      const confirm = await this.$refs['confirmDeleteDialog'].show()
      if (confirm) {
        this.$store.dispatch('setLoading', true)
        try {
          const user = this.getCurrentUserData()
          await this.$Fetcher.DeleteStudentGroup(id, user.id, user.token)
          this.$refs['confirmDeleteDialog'].hide()
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '刪除成功',
          })
          this.tablePage = 1;
          this.getList();
        } catch (err) {
          if (err) {
            this.setAlert({
              type: 'error',
              message: err,
            })
          }
          this.$store.dispatch('setLoading', false)
        }
      }
    },
  },

  async mounted() {
    this.getQueries();
  },
}
</script>
