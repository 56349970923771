<template>
  <Dialog
    :open="open"
    :type="type"
    :title="title"
    :disabled="disabled"
    :loading="loading"
    enableCancel
    confirmBtnTitle="確認"
    @close="_dialogAction"
  />
</template>
  
<script>
import Dialog from '@/components/layout/Dialog.vue';
export default {
  name: 'ConfirmDialog',
  components: {
    Dialog
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "info",
    },
    title: {
      type: String,
      required: false,
      default: '是否確定要刪除此項目？此動作無法復原。',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableCloseConfirmed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    open: false,
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  methods: {
    show() {
      this.open = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    hide() {
      this.open = false;
    },

    _dialogAction(action) {
      if (action === true) {
        if (!this.disableCloseConfirmed) {
          this.open = false;
        }
        this.resolvePromise(true);
      } else {
        this.open = false;
        this.resolvePromise(false);
      }
    },
  },
}
</script>


  